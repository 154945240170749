import React, { useState } from "react";
import "../styles.css";
function Maps() {

  return (
    <>
    <div class="section-maps">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <h2>Find Us</h2>
            <p>21 Nightingale Ave, <br /> Darlington DL1 2AY</p>

            <a href="tel:+441325 350841" class="phone-link">01325 350841</a>

            <br />
            <br />
            <br />

            <h2>Hours</h2>
              <p>
                Monday-Sunday<br />4pm – 11pm<br />
              </p>
          </div>
          <div class="col-md-8">
            <iframe
            class="map"
            title="Bengal Express's Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2314.448666287602!2d-1.5332822098718593!3d54.5432159021679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e9bbac114539f%3A0xd5d8774924f999d2!2sBengal%20Express!5e0!3m2!1sen!2sbd!4v1729878135278!5m2!1sen!2sbd"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Maps;