import React from 'react';

import { StyleSheet, Text, View, ScrollView, ActivityIndicator } from 'react-native';
import { colors } from './../constants/colors.js';
import { fonts } from './../constants/fonts.js';

export default class TermsScreen extends React.Component {

  render() {
    return (
      <View style={styles.main}>

          <View style={styles.header}>
            <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>
              <Text style={{color: colors.white, fontWeight: "700", fontSize: 24}}>Terms & Conditions</Text>
            </View>
          </View>

        <ScrollView style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>

<Text style={styles.h1}>IMPORTANT LEGAL NOTICE</Text>
<Text style={styles.p}>This page (together with our cookies policy) sets out the terms and conditions ("Website Terms") on which we, Yuze Digital Ltd ("we", "our" or "Yuze"), provide access to our website  www.Yuze.uk and any Yuze mobile application through which you order products (together, "the Website"). Please read these Website Terms carefully before ordering any products through the Website. By ordering products through the Website (whether now or in the future), you agree to be bound by these Website Terms. Use of the Website is also subject to these Website Terms.</Text>
<Text style={styles.p}>We reserve the right to change these Website Terms from time to time by changing them on this page. We advise you to print a copy of these Website Terms for future reference. These Website Terms are only in the English language.</Text>
<Text style={styles.p}>Use of your personal information submitted via the Website is governed by our Privacy Notice and Cookies Policy.</Text>
<Text style={styles.p}>For the avoidance of doubt, please note that references to "Website" in these Website Terms include any current or future version of our website www.Yuze.uk and any Yuze mobile application through which you access and use our Website, in each case whether accessed through any current or future platform or device (including without limitation any mobile website, mobile application, affiliate website or related website for accessing and using our Website that may be developed from time to time).</Text>
<Text style={styles.p}>By accessing any part of the Website, you indicate that you accept these Website Terms. If you do not accept these Website Terms, you should leave the Website immediately, and you will not be able to order any products through the Website.</Text>


<Text style={styles.h1}>TERMS AND CONDITIONS OF USE AND SALE</Text>
<Text style={styles.h2}>1. INTRODUCTION AND OUR ROLE</Text>
<Text style={styles.p}>1.1. Company details: Bengal Express Limited is a company registered in England and Wales with registered company number , whose registered office is at 3rd Floor, 9 Market Place, Barnard Castle, DL12 8NF.</Text>
<Text style={styles.p}>1.2. Bengal Express.
</Text>
<Text style={styles.p}>1.3. Product Orders: We provide a way for you to communicate your orders (“Order” or "Orders") for products (“Product” or "Products") to Bengal Express restaurants in the UK (“Restaurant” or "Restaurants") displayed on their Website. The legal contract for the supply and purchase of Products is between you and Bengal Express we will conclude the sale of Products on behalf of, and as commercial agent for Bengal Express in all cases.</Text>
<Text style={styles.p}>1.4 Delivery Services: Bengal Express provide their own delivery service in order to bring the Product(s) you order to you. In this case, Bengal Express  may charge you a delivery fee (which we will collect on behalf of Bengal Express).</Text>
<Text style={styles.h2}>2. WEBSITE ACCESS AND TERMS</Text>
<Text style={styles.p}>2.1. Website access: You may access some areas of the Website without making an Order or registering your details with us. Most areas of the Website are open to everyone.</Text>
<Text style={styles.p}>2.2. Acceptance of terms: By accessing any part of the Website, you indicate that you accept these Website Terms. If you do not accept these Website Terms, you should leave the Website immediately, and you will not be able to order any Products through the Website.</Text>
<Text style={styles.p}>2.3. Revision of terms: We may revise these Website Terms at any time. You should check the Website regularly to review the current Website Terms, because they are binding on you. You will be subject to the policies and terms and conditions in force at the time that you place an Order through us.</Text>
<Text style={styles.p}>2.4. Responsibility: You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your Internet connection are aware of these Website Terms and that they comply with them.</Text>
<Text style={styles.h2}>3. YOUR STATUS</Text>
<Text style={styles.p}>3.1. Capacity and age: By placing an Order through the Website, you warrant that:
</Text>
<Text style={styles.p}>3.1.1. You are legally capable of entering into binding contracts with Restaurant; and
</Text>
<Text style={styles.p}>3.1.2. You are at least 18 years old.
</Text>
<Text style={styles.p}>3.2. You acknowledge and agree that if you have a specific food allergy or intolerance, you will contact the Restaurant directly to check that the food is suitable for you, before placing your order directly with them.
</Text>
<Text style={styles.p}>3.3. Alcohol, cigarettes and other smoking products:
</Text>
<Text style={styles.p}>3.3.1. You acknowledge and agree that: it is an offence for any person under the age of 18 to buy, or attempt to buy, alcohol in the UK, or for any person to buy, or attempt to buy, alcohol, tobacco or other smoking products in the UK on behalf of any person who is under the age of 18;</Text>
<Text style={styles.p}>3.3.2. If your Order includes any alcohol, cigarettes or other smoking products, you will be asked to provide proof of your age on collection or delivery of your Order. If you are unable to provide proof that you are aged 18 or over to the satisfaction of your chosen Restaurant, or if the Restaurant reasonably believes that the alcohol, cigarettes or other smoking products you have ordered have been bought by you on behalf of someone under the age of 18, the Restaurant reserves the right not to complete the delivery of the alcohol, cigarettes or other smoking products to you.
</Text>
<Text style={styles.h2}>4. HOW TO MAKE AN ORDER AND HOW IT IS PROCESSED</Text>
<Text style={styles.p}>4.1. Compiling your Order: Once you have selected the Products you wish to order from the menu of your chosen Restaurant and provided the other required information, you will be given the opportunity to submit your Order by clicking or selecting the "proceed", "place my order" or similar button. It is important that you check all the information that you enter and correct any errors before clicking or selecting this button; once you do so you will be entering into a contract with the Restaurant and errors cannot be corrected (subject to paragraph 4.2. below). Where you have not logged in or created an account previously, then you may have the option to continue with your Order as a guest. By electing to complete your Order as a guest, you are agreeing that Yuze will create a temporary Yuze guest account for you which will, unless otherwise stated in these Website Terms, be accessible only (a) from the device which you use to place the Order; and (b) for a period of up to twelve months following the date of the Order.  Your access to your temporary guest account will expire on the “Expiry Date” which shall be the earlier of (i) the date falling six months after the date on which you last accessed the temporary guest account; (ii) the date falling twelve months after the date on which the temporary guest account was created; and (iii) the date on which you logout of your temporary guest account, or clear the cache on the device which you used when the temporary guest account was created.</Text>
<Text style={styles.p}>4.2. Amending or cancelling your Order: Once you have submitted your Order and your payment has been authorised, you will not be entitled to change or cancel your Order, nor will you be entitled to a refund (please refer to paragraphs 4.4 and 5.6 for details of the process relating to rejected Orders and refunding of payment). If you wish to change or cancel your Order, you may contact our Customer Care team as described in paragraph 6.3 and they will attempt to contact the Restaurant in order to communicate your requests. However, there is no guarantee that we will be able to reach the Restaurant or that the Restaurant will agree to your requests as they may have already started processing your Order.
</Text>
<Text style={styles.p}>4.3. Payment authorisation: Where any payment you make is not authorised, your Order will not be processed or communicated to the relevant Restaurant.
</Text>
<Text style={styles.p}>4.4. Processing your Order and Restaurant rejections: On receipt of your Order, we will send it to the relevant Restaurant and will notify you by email that your Order has been received and is being processed. Please note that any confirmation page that you may see on the Website and any Order confirmation e-mail that you may receive each confirm that you have a contract for the sale of Products with a Restaurant but does not necessarily mean that your Order will be fulfilled by the Restaurant. We encourage all our Restaurants to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a Restaurant rejects your Order. However, Restaurants have the ability to reject Orders at any time because they are too busy, due to weather conditions or for any other reason. In doing so, Restaurants will be in breach of their agreement with you and any payment made in respect of the order will be returned to you in accordance with paragraph 5.6 below.
</Text>
<Text style={styles.p}>4.5. Delivery of your Order: Estimated times for deliveries and collections are provided by the Restaurants and are only estimates. Neither we nor the Restaurants guarantee that Orders will be delivered or will be available for collection within the estimated times.
</Text>
<Text style={styles.p}>4.6. Commercial agency: For the avoidance of doubt, any orders processed by Yuze through the Website are processed by Yuze in its capacity as commercial agent of the relevant Restaurant.
</Text>
<Text style={styles.h2}>5. PRICE AND PAYMENT</Text>
<Text style={styles.p}>5.1. VAT and delivery costs: Prices will be as quoted on the Website. These prices include VAT but may exclude delivery costs (if you opt for delivery instead of collection) and any administration or service charge imposed by the Restaurant. These will be added to the total amount due where applicable.
</Text>
<Text style={styles.p}>5.2. Incorrect pricing: This Website contains a large number of menus and it is possible that some of the menus may include incorrect prices. If the correct price for an Order is higher than the price stated on the Website, we will normally contact you before the relevant Order is dispatched. In such an event, neither we nor the relevant Restaurant is under any obligation to ensure that the Order is provided to you at the incorrect lower price or to compensate you in respect of incorrect pricing.
</Text>
<Text style={styles.p}>5.3. Payment methods: Payment for Orders must be made by an accepted credit or debit card through the Website or in cash to the Restaurant at the point of delivery to, or collection by, you.

<Text style={styles.p}>5.4. Card payments: If you pay by credit or debit card, you may be required to show the card to the Restaurant at the time of delivery or collection as proof of identification and so that they can check that the card corresponds with the receipt data for the Order. Please note that from time to time there may be delays with the processing of card payments and transactions; this may result in payments taking up to sixty (60) days to be deducted from your bank account or charged to your credit or debit card.</Text>

<Text style={styles.p}>5.5. Credit and discount vouchers: A credit or discount may apply to your Order if you use a promotional voucher or code recognised by the Website and endorsed by Yuze, and you pay for any balance by credit or debit card. Please refer to our Voucher Terms and Conditions for the full terms and conditions applicable to the use of credit and discount vouchers. Please note that because of standard banking procedures, your bank or card issuer will initially "ring-fence" the full amount of the Order (before any credit or discount) in your account for between 3 to 5 working days (or longer, depending on your bank or card issuer), and this amount will therefore be unavailable in your account for that period. The credit or discount will be applied at the time your bank or card issuer transfers the funds for your Order to us, at which point the credit or discounted amount will not be transferred to us and will instead be released by your bank or card issuer back into your available balance. You acknowledge and agree that neither we nor the relevant Restaurant will be responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account. Where you use the Website as a guest, any account credit which you accrue against your temporary guest account can only be redeemed for an Order placed via your temporary guest account and cannot be transferred for use on another account.
</Text><Text style={styles.p}>5.6. Rejected Orders: Because of standard banking procedures, once you have submitted an Order that you are paying for by credit or debit card and your payment has been authorised, your bank or card issuer will "ring-fence" the full amount of your Order. If your Order is subsequently rejected by the Restaurant (as described in paragraph 4.4 above) or cancelled for any other reason, your bank or card issuer will not transfer the funds for the Order to us, and will instead release the relevant amount back into your available balance. However, this may take between 3 to 5 working days (or longer, depending on your bank or card issuer). You acknowledge and agree that neither we nor the relevant Restaurant will be responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account.
</Text>
<Text style={styles.h2}>6. CUSTOMER CARE</Text>
<Text style={styles.p}>6.1. General: Customer care is extremely important to us. Subject to paragraphs 6.5 and 11, our Customer Care team will therefore try to assist you where possible if you have any problems with your Order. You can contact our Customer Care team by clicking or selecting the "Need help?", "Help" or similar button or by calling the telephone number shown on the Website.
</Text>
<Text style={styles.p}>6.2. Questions about your Order: If your Order is taking longer than expected or you have any other problems with your Order, you can contact our Customer Care Team as described above and one of our Customer Care Advisers will attempt to contact the Restaurant in order to follow up on your query.
</Text>
<Text style={styles.p}>6.3. Changing or cancelling your Order: If you wish to change or cancel your Order after it has been submitted and payment has been authorised, you may contact our Customer Care team as described above and they will attempt to contact the Restaurant in order to communicate your requests. However, there is no guarantee that we will be able to reach the Restaurant or that the Restaurant will agree to your requests as they may have already started processing your Order.
</Text>
<Text style={styles.p}>6.4. Complaints or feedback: In the event that you are dissatisfied with the quality of any Products or the service provided by a Restaurant, please consider providing feedback in the form of ratings, comments and reviews on the Website (together, "Reviews") to reflect your experience. The Reviews are an important part of our quality control process.
</Text>
<Text style={styles.p}>6.5. Compensation: If you are dissatisfied with the quality of any Products or the service provided by a Restaurant and wish to seek a refund, a proportionate price reduction or any other compensation, you should contact the Restaurant directly to lodge your complaint and, where appropriate, follow the Restaurant's own complaint procedures. If you are unable to contact the Restaurant, or the Restaurant refuses to deal with your complaint, you can contact our Customer Care Team as described above within 72 hours of placing your Order and one of our Customer Care Advisers will attempt to contact the Restaurant in order to request compensation on your behalf. Please note, however, that the legal contract for the supply and purchase of Products is between you and the Restaurant that you place your Order with. We have no control over Restaurants and the quality of the Products or service that they provide, and we are not able to provide, and have no responsibility or liability for providing, any compensation to you on behalf of any Restaurant.</Text>
<Text style={styles.h2}>7. LICENCE</Text>
<Text style={styles.p}>7.1. Terms of permitted use: You are permitted to use the Website and print and download extracts from the Website for your own personal non-commercial use on the following basis:
</Text>
<Text style={styles.p}>7.1.1. You must not misuse the Website (including by hacking or \"scraping\").
</Text>
<Text style={styles.p}>7.1.2. Unless otherwise stated, the copyright and other intellectual property rights in the Website and in material published on it (including without limitation photographs and graphical images) are owned by us or our licensors. These works are protected by copyright laws and treaties around the world and all rights are reserved. For the purposes of these Website Terms, any use of extracts from the Website other than in accordance with paragraph 7.1 is prohibited.
</Text>
<Text style={styles.p}>7.1.3. You must not modify the digital or paper copies of any materials that you print off in accordance with paragraph 7.1 and you must not use any pictures, photographs or any other graphics, video or audio sequences separately from any accompanying text.
</Text>
<Text style={styles.p}>7.1.4. You must ensure that our status as the author of the material on the Website is always acknowledged.
</Text>
<Text style={styles.p}>7.1.5. You are not allowed to use any of the materials on the Website or the Website itself for commercial purposes without obtaining a licence from us to do so.
</Text>
<Text style={styles.p}>7.2. Limitation on use: Except as stated in paragraph 7.1, the Website may not be used, and no part of the Website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service, without our prior written permission.
</Text><Text style={styles.p}>7.3. Reservation of rights: Any rights not expressly granted in these Website Terms are reserved.
</Text><Text style={styles.h2}>8. WEBSITE ACCESS</Text>
</Text><Text style={styles.p}>8.1. Website availability: While we try to ensure the Website is normally available twenty four (24) hours a day, we do not undertake any obligation to do so, and we will not be liable to you if the Website is unavailable at any time or for any period.
</Text><Text style={styles.p}>8.2. Suspension of access: Access to the Website may be suspended temporarily at any time and without notice.
</Text><Text style={styles.p}>8.3. Information security: The transmission of information via the Internet is not completely secure. Although we take the steps required by law to protect your information, we cannot guarantee the security of your data transmitted to the Website; any transmission is at your own risk.
</Text><Text style={styles.h2}>9. VISITOR MATERIAL AND REVIEWS</Text>
<Text style={styles.p}>9.1. General:
</Text><Text style={styles.p}>9.1.1. Other than personally identifiable information, which is covered under our Privacy Notice, any material you post, upload or transmit or upload to the Website (including without limitation Reviews) Visitor Material will be considered non-confidential and non-proprietary. By posting, uploading or transmitting any Visitor Material, you represent and warrant that you own or otherwise control all of the rights to such Visitor Material. You agree that we will have no obligations with respect to any Visitor Material, and that we and anyone we designate will be free to copy, disclose, distribute, incorporate and otherwise use any Visitor Material and all data, images, sounds, text and other things embodied in it for any and all commercial or non-commercial purposes.
</Text><Text style={styles.p}>9.1.2. You represent and warrant that any Visitor Material you post, upload or transmit does not and will not breach any of the restrictions in paragraphs 9.2 to 9.3 below.
</Text><Text style={styles.p}>9.2. Visitor Material Policy: You are prohibited from posting, uploading or transmitting to or from the Website any Visitor Material (including any Reviews) that:
</Text><Text style={styles.p}>9.2.1. breaches any applicable local, national or international law;
</Text><Text style={styles.p}>9.2.2. is unlawful or fraudulent;
</Text><Text style={styles.p}>9.2.3. amounts to unauthorised advertising; or
</Text><Text style={styles.p}>9.2.4. contains viruses or any other harmful programs.
</Text><Text style={styles.p}>9.3. Visitor Reviews Policy: In particular (but without limitation), any Reviews that you submit through the Website must not:
</Text><Text style={styles.p}>9.3.1. contain any defamatory, obscene or offensive material;
</Text><Text style={styles.p}>9.3.2. promote violence or discrimination;
</Text><Text style={styles.p}>9.3.3. infringe the intellectual property rights of another person;
</Text><Text style={styles.p}>9.3.4. breach any legal duty owed to a third party (such as a duty of confidence);
</Text><Text style={styles.p}>9.3.5. promote illegal activity or invade another\'s privacy;
</Text><Text style={styles.p}>9.3.6. give the impression that they originate from us; or
</Text><Text style={styles.p}>9.3.7. be used to impersonate another person or to misrepresent your affiliation with another person.
</Text><Text style={styles.p}>9.4. Removal of Reviews: The prohibited acts listed in paragraphs 9.2 and 9.3 above are non-exhaustive. We reserve the right (but do not undertake, except as required by law, any obligation) and have the sole discretion to remove or edit at any time any Reviews or other Visitor Material posted, uploaded or transmitted to the Website that we determine breaches a prohibition in paragraphs 9.2 or 9.3 above, is otherwise objectionable or may expose us or any third parties to any harm or liability of any type, or for any other reason.
</Text><Text style={styles.p}>9.5. Use of Reviews: The Reviews and other Visitor Material contained on the Website are for information purposes only and do not constitute advice from us. Reviews and Visitor Material reflect the opinions of customers who have ordered through the Website or other third parties, and any statements, advice or opinions provided by such persons are theirs only. Accordingly, to the fullest extent permitted by law, we assume no responsibility or liability to any person for any Reviews or other Visitor Material, including without limitation any mistakes, defamation, obscenity, omissions or falsehoods that you may encounter in any such materials.
</Text><Text style={styles.p}>9.6. Images: Any images of food displayed on the Website are provided as a design feature of the Website only and may not be either (a) an image of food prepared or produced by the Restaurant from which you choose to order; or (b) representative of the food you receive from a Restaurant.
</Text><Text style={styles.p}>9.7. Liability: You agree to indemnify us against any losses, damages and claims (and all related costs) incurred by or made against us by a Restaurant or any other third party arising out of or in connection with any Reviews or other Visitor Material that you provide in breach of any of the representations and warranties, agreements or restrictions set forth in this paragraph 9.
</Text><Text style={styles.p}>9.8. Disclosure to authorities and courts: You acknowledge that we will fully co-operate with any competent authority requesting or directing us to disclose the identity or location of anyone posting any Reviews or other Visitor Material in breach of paragraph 9.2 or 9.3 or any other applicable restriction and you release us to the fullest extent permitted by law from all liability in relation to such disclosure.
</Text><Text style={styles.h2}>10. LINKS TO AND FROM OTHER WEBSITES
</Text><Text style={styles.p}>10.1. Third party websites: Links to third party websites on the Website are provided solely for your convenience. If you use these links, you leave the Website. We have not reviewed and do not control any of these third party websites (and are not responsible for these websites or their content or availability). We do not endorse or make any representation about these websites, their content, or the results from using such websites or content. If you decide to access any of the third party websites linked to the Website, you do so entirely at your own risk.
</Text><Text style={styles.p}>10.2. Linking permission: You may link to the Website's homepage (www.just-eat.co.uk), provided that:
</Text><Text style={styles.p}>10.2.1. you do so in a fair and legal way which does not damage or take advantage of our reputation;
</Text><Text style={styles.p}>10.2.2. you do not establish a link from a website that is not owned by you or in a way that suggests a form of association with or endorsement by us where none exists;
</Text><Text style={styles.p}>10.2.3. any website from which you link must comply with the content standards set out in these Website Terms (in particular paragraph 9 (Visitor Materials and Reviews));
</Text><Text style={styles.p}>10.2.4. we have the right to withdraw linking permission at any time and for any reason.
</Text><Text style={styles.h2}>11. DISCLAIMERS
</Text><Text style={styles.p}>11.1. Website information: While we try to ensure that information on the Website is correct, we do not promise it is accurate or complete. We may make changes to the material on the Website, or to the functionality, Products and prices described on it, at any time without notice. The material on the Website may be out of date, and we make no commitment to update that material.
</Text><Text style={styles.p}>11.2. Allergy, dietary and other menu information:  When a Restaurant signs up with us, they have to provide us with up-to-date menu information. We then include this on their dedicated page on the Website. Where this information includes allergy or other dietary information, we will do our best to republish this information on the website or app exactly as it appears on the restaurant's menu. If you have, or someone you are ordering for has, a concern about food allergies, intolerances or other dietary preferences, you should always contact the Restaurant directly before placing your order. Please do not use the "leave a note for the Restaurant" box for your food allergies or intolerances, please contact the Restaurant directly.
</Text><Text style={styles.p}>11.3. Restaurant actions and omissions:  The legal contract for the supply and purchase of Products is between you and the Restaurant that you place your Order with. We have no control over the actions or omissions of any Restaurants. Without limiting the generality of the foregoing, you acknowledge and accept the following by using the Website:
</Text><Text style={styles.p}>11.3.1. We do not give any undertaking that the Products ordered from any Restaurant through the Website will be of satisfactory quality or suitable for your purpose and we disclaim any such warranties.
</Text><Text style={styles.p}>11.3.2. Estimated times for deliveries and collections are provided by the Restaurants and are only estimates. Neither we nor the Restaurants guarantee that Orders will be delivered or will be available for collection within the estimated times.
</Text><Text style={styles.p}>11.3.3. We encourage all our Restaurants to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a Restaurant rejects your Order. However, we do not guarantee that Restaurants will accept and fulfil all Orders, and Restaurants have the discretion to reject Orders at any time because they are too busy, if you fail to provide proof of age for purchases of alcohol, cigarettes or other smoking products when required, due to weather conditions or for any other reason. Restaurants will not be obliged to deliver an Order to an address outside of their set delivery radius, which may change from time to time.
</Text><Text style={styles.p}>11.3.4. The foregoing disclaimers do not affect your statutory rights against any Restaurant.
</Text><Text style={styles.p}>11.4. Exclusion of terms: We provide you with access to and use of the Website on the basis that, to the maximum extent permitted by law, we exclude all representations, warranties, conditions, undertakings and other terms in relation to the Website and your use of it (including any representations, warranties, conditions, undertakings and other terms which might otherwise apply to the Website and your use of it, or be otherwise implied or incorporated into these Website Terms, by statute, common law or otherwise ).
</Text><Text style={styles.h2}>12. LIABILITY
</Text><Text style={styles.p}>12.1. General: Nothing in these Website Terms excludes or limits our liability for death or personal injury arising from our negligence, our liability for fraudulent misrepresentation, or any other liability which cannot be excluded or limited under applicable law. Nothing in these Website Terms affects your statutory rights.
</Text><Text style={styles.p}>12.2. Exclusion of liability: Subject to clause 12.1, we will under no circumstances whatever be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if forseeable, arising under or in connection with the Website (including the use, inability to use or the results of use of the Website) for:
</Text><Text style={styles.p}>12.2.1. any loss of profits, sales, business, or revenue;
</Text><Text style={styles.p}>12.2.2. loss or corruption of data, information or software;
</Text><Text style={styles.p}>12.2.3. loss of business opportunity;
</Text><Text style={styles.p}>12.2.4. loss of anticipated savings;
</Text><Text style={styles.p}>12.2.5. loss of goodwill; or
</Text><Text style={styles.p}>12.2.6. any indirect or consequential loss.
</Text><Text style={styles.p}>12.3. Limitation of liability: Subject to clauses 11, 12.1 and 12.2, our total liability to you in respect of all other losses arising under or in connection with the Website or your use of it, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed twice the value of your Order or £100, whichever is lower.
</Text><Text style={styles.p}>12.4. Additional costs: You assume full and sole responsibility for any additional or associated costs that you may incur in connection with or as a result of your use of the Website, including without limitation costs relating to the servicing, repair or adaptation of any equipment, software or data that you may own, lease, license or otherwise use.
</Text><Text style={styles.h2}>13. TERMINATION
</Text><Text style={styles.p}>13.1. Grounds for termination: We may terminate or suspend (at our absolute discretion) your right to use the Website immediately by notifying you in writing (including by email) if we believe in our sole discretion that:
</Text><Text style={styles.p}>13.1.1. you have used the Website in breach of paragraph 7.1 (License);
</Text><Text style={styles.p}>13.1.2. you have posted Reviews or other Visitor Material in breach of paragraphs 9.2 or 9.3 (Visitor Material and Reviews);
</Text><Text style={styles.p}>13.1.3. you have breached paragraph 10.2 (Links to and from other websites); or
</Text><Text style={styles.p}>13.1.4. you have breached any other material terms of these Website Terms.
</Text><Text style={styles.p}>13.2. Obligations upon termination: Upon termination or suspension you must immediately destroy any downloaded or printed extracts from the Website.
</Text><Text style={styles.h2}>14. WRITTEN COMMUNICATIONS
</Text><Text style={styles.p}>14.1. Applicable laws require that some of the information or communications we send to you should be in writing. When using the Website or ordering Products via the Website, you accept that communication with us will be mainly electronic. We will contact you by email or provide you with information by posting notices on the Website. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing. This condition does not affect your statutory rights.
</Text><Text style={styles.h2}>15. EVENTS OUTSIDE OUR CONTROL
</Text><Text style={styles.p}>15.1. We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Website Terms that is caused by events outside our reasonable control ("Force Majeure Event").
</Text><Text style={styles.p}>15.2. A Force Majeure Event includes any act, event, non-occurrence, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:
</Text><Text style={styles.p}>15.2.1. strikes, lock-outs or other industrial action;
</Text><Text style={styles.p}>15.2.2. civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, epidemic or pandemic, war (whether declared or not) or threat or preparation for war;
</Text><Text style={styles.p}>15.2.3. fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;
</Text><Text style={styles.p}>15.2.4. impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport;
</Text><Text style={styles.p}>15.2.5. impossibility of the use of public or private telecommunications networks; and
</Text><Text style={styles.p}>15.2.6. the acts, decrees, legislation, regulations or restrictions of any government.
</Text><Text style={styles.p}>15.3. Our performance under these Website Terms is deemed to be suspended for the period that any Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our reasonable endeavours to bring any Force Majeure Event to a close or to find a solution by which our obligations under these Website Terms may be performed despite the Force Majeure Event.
</Text><Text style={styles.h2}>16. ADDITIONAL TERMS
</Text><Text style={styles.p}>16.1. Privacy Notice: We are committed to protecting your privacy and security. All personal data that we collect from you will be processed in accordance with our Privacy Notice. You should review our Privacy Notice.
</Text><Text style={styles.p}>16.2. Other terms: You should also review our Cookies Policy for information regarding how and why we use cookies to improve the quality of the Website and your use of it, our Voucher Terms and Conditions for information regarding the use of credits and promotional discounts on the Website, and our Competition Terms and Conditions for information regarding the terms applicable to competitions that we may run from time to time. All of these are incorporated into these Website Terms by this reference.
</Text><Text style={styles.p}>16.3. Severability: If any of these Website Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.
</Text><Text style={styles.p}>16.4. Entire agreement: These Website Terms and any document expressly referred to in them constitute the whole agreement between you and us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us relating to the subject matter of any contract.
</Text><Text style={styles.p}>16.5. No waiver: Any failure or delay by you or us in enforcing (in whole or in part) any provision of these Website Terms will not be interpreted as a waiver of your or our rights or remedies.
</Text><Text style={styles.p}>16.6. Assignment: You may not transfer any of your rights or obligations under these Website Terms without our prior written consent. We may transfer any of our rights or obligations under these Website Terms without your prior written consent to any of our affiliates or any business that we enter into a joint venture with, purchase or are sold to.
</Text><Text style={styles.p}>16.7. Headings: The headings in these Website Terms are included for convenience only and shall not affect their interpretation.
</Text><Text style={styles.h2}>17. GOVERNING LAW AND JURISDICTION
</Text><Text style={styles.p}>17.1. These Website Terms shall be governed by and construed in accordance with English law. You can bring legal proceedings in respect of Website Terms in the English courts. If you live in a part of the United Kingdom other than England or any other Member State of the European Union, you can bring legal proceedings in respect of these Website Terms in either the English courts or the courts of your home country.
</Text><Text style={styles.p}>17.2 As a consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident.  Nothing in these Website Terms, including the paragraph above, affects your rights as a consumer to rely on such mandatory provisions of local law.
</Text>
<View style={styles.page}></View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white,
    fontFamily: fonts.regular,
  },
  container: {
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
  },
  header: {
    height: 90,
    paddingTop: 20,
    paddingHorizontal: 30,
    backgroundColor: colors.primary,
  },
  page: {
    height: 100,
  },
  h1: {
    fontSize: 22,
    fontFamily: fonts.bold,
    marginTop: 30,
    marginBottom: 20,
    color: colors.text
  },
  h2: {
    fontSize: 16,
    fontFamily: fonts.bold,
    color: colors.text,
    marginTop: 20,
    marginBottom: 20,
  },
  p: {
    color: colors.text,
    marginBottom: 10,
    fontFamily: fonts.regular,
  }
})
